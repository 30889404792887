/* eslint-disable @typescript-eslint/camelcase */
import { PrintDataEntitas } from "@/domain/entities/Booking";
import { createI18n } from "vue-i18n";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my";

import {
  allPrices,
  getWaterMark,
  headerSection,
  originDestinationSection,
  commoditySection,
  referencesSection,
  codSection,
  topPriceSection,
  weightVolumeSection,
  topInformationSection,
  detailFooterSection,
  bottomInformationSection,
  productBottomSection,
  priceBottomSection,
  QRCodeSection,
  variablesDefine
} from "./part-layout/parts"

export const layoutSection = async (
  params: {
    doc: any;
    bookingType: string;
    accountType: string;
    data: PrintDataEntitas;
    pieceData: any;
    barcode: string;
    vendorRefCode: string;
    barcodeValue: string;
    vendorRefCodeValue: string;
    logo: string;
    shipmentId: string;
    startX: number;
    startY: number;
  },
  language = "id"
) => {

  // LANGUAGE
  const i18nInstance = createI18n({
    legacy: false,
    locale: language,
    fallbackLocale: "id",
    globalInjection: false,
    messages: {
      en,
      id,
      my
    }
  });
  const translate = i18nInstance.global.t;

  // DEFINE VARIABLES
  const { doc, bookingType, accountType, data, pieceData, barcode, vendorRefCode, barcodeValue, vendorRefCodeValue, logo, shipmentId, startX, startY } = params;
  const { logoAspectRatio, isCod, isDfod, shipmentAlgo, isPriceOfCOD, externalRefValue, productType, ursaCode, qrcodeValue, showCodRetail, determinantIsCodRetailTop, isPad, watermarkCod, watermarkDfod } = await variablesDefine(doc, data, { shipmentId, logo, bookingType, accountType })
  const { totalBiaya, biayaKirim, goodsPrice, dfodPrice, asuransi, pajak, returnFee, codFee, totalWeight, estimateSla, lainLain, isBigpackVolume, isJumbopackvolume, isHubToHub } = allPrices(data, { showCodRetail, isDfod, language });

  // DEFINE FUNCTION
  await getWaterMark(doc, { watermarkCod, isPad, watermarkDfod, productType }, { startX, startY });
  headerSection(doc, { startX, startY, }, data, { logo, logoAspectRatio, ursaCode, barcode, barcodeValue, language, estimateSla, translate });
  originDestinationSection(doc, { startX, startY }, data, { translate });
  await commoditySection(doc, { startX, startY }, data, { language, isPad, isCod, isDfod, externalRefValue, vendorRefCodeValue, vendorRefCode, });
  await referencesSection(doc, { startX, startY }, data, { externalRefValue, vendorRefCodeValue, vendorRefCode });
  codSection(doc, { startX, startY }, data, { isCod, isDfod, isPad, shipmentAlgo, bookingType, accountType, isPriceOfCOD, totalBiaya, biayaKirim, showCodRetail, asuransi, determinantIsCodRetailTop, dfodPrice, goodsPrice, translate });
  topPriceSection(doc, data, { startX, startY }, { totalWeight, determinantIsCodRetailTop, shipmentAlgo, bookingType, accountType, isPriceOfCOD, pajak, showCodRetail, isDfod, lainLain, translate });
  weightVolumeSection(doc, { startX, startY }, { productType, pieceData, isBigpackVolume, isJumbopackvolume, isHubToHub });
  await topInformationSection(doc, { startX, startY }, { translate });
  detailFooterSection(doc, { startX, startY }, data, { logo, logoAspectRatio, barcode, barcodeValue, estimateSla, translate });
  bottomInformationSection(doc, { startX, startY }, { translate });
  productBottomSection(doc, { startX, startY, }, data, { isCod, isPad, isDfod, productType, language, isHubToHub });
  priceBottomSection(doc, data, { startX, startY, }, { showCodRetail, isDfod, totalWeight, shipmentAlgo, bookingType, accountType, isPriceOfCOD, biayaKirim, asuransi, pajak, lainLain, goodsPrice, returnFee, dfodPrice, codFee, translate });
  QRCodeSection(doc, { startX, startY, }, data, { qrcodeValue, showCodRetail, isDfod, shipmentAlgo, bookingType, accountType, isPriceOfCOD, isCod, translate, lainLain });
};
